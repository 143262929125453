<template>
  <div>
    <el-tabs type="border-card"
             v-model="activeTab">
      <el-tab-pane label="标准授权"
                   name="standard" :disabled="disabledBasics">
        <BuyTab v-if="activeTab === 'standard'"
                @payTypeChange="payTypeChange"
                :outTradeno="standardOutTradeno"
                :title="standardTitle"
                :price="standardPrice"
                :qrcodeUrl='qrcodeUrl'
                :couponPrice="couponPrice"
                :realPrice="price"
                :desc="priceDesc"
        />
      </el-tab-pane>
      <el-tab-pane label="高级授权"
                   name="advanced">
        <BuyTab v-if="activeTab === 'advanced'"
                @payTypeChange="payTypeChange"
                :outTradeno="advancedOutTradeno"
                :title="advancedTitle"
                :price="advancedPrice"
                :qrcodeUrl='qrcodeUrl'
                :couponPrice="couponPrice"
                :realPrice="price"
                :desc="price2Desc"
        />
      </el-tab-pane>
    </el-tabs>
    <el-row :gutter="10" style="margin-top:10px;" v-if="couponOpen">
      <el-col :span="4">
        <span class="coupon-label">优惠券：</span>
      </el-col>
      <el-col :span="16">
        <el-input v-model="coupon" placeholder="请输入优惠券编码"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="setCoupon"
                   type="primary">确认</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top:10px;">
      <el-col :span="14"><span class="hint"><i class="el-icon-warning"></i> 如已成功支付但较长时间没有跳转请点击"我已支付"</span></el-col>
      <el-col :span="5">
        <el-button @click="confirmPay"
                   type="success">我已支付</el-button>
      </el-col>
      <el-col :span="5">
        <el-button @click="exit">取 消</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import BuyTab from './BuyTab'

// eslint-disable-next-line no-unused-vars
import { getWxPayQrcode, queryOrderStatus, queryOrderStatusAli, changeOrderStatus, changeOrderStatusAli, getAliPayQrcode } from '@/api/pay'
let timeOut = null
export default {
  name: 'Buy',
  components: { BuyTab },
  props: {
    pluginId: {
      type: Number,
      required: true
    },
    standardTitle: {
      type: String,
      required: true,
      default: ''
    },
    standardPrice: {
      type: Number,
      required: true,
      default: 0
    },
    advancedTitle: {
      type: String,
      required: true,
      default: ''
    },
    advancedPrice: {
      type: Number,
      required: true,
      default: 0
    },
    disabledBasics: {
      type: Boolean,
      default: false
    },
    activeTabIndex: {
      type: String,
      default: 'standard'
    },
    isUpdate: {
      type: Number,
      default: 0
    },
    couponOpen: {
      type: Boolean,
      default: false
    },
    priceDesc: {
      type: String,
      default: '一年免费更新升级，仅支持自营项目使用，禁止用于外包'
    },
    price2Desc: {
      type: String,
      default: '长期免费更新升级，支持自营项目、外包项目使用'
    }
  },
  data () {
    return {
      activeTab: '', // standard - 标准授权、 advanced - 高级授权
      activePayType: '', // 三种支付方式, 分别为:wechatPay、aliPay、balance
      qrcodeUrl: '', // 二维码
      standardOutTradeno: '', // 标准版订单编号
      advancedOutTradeno: '', // 高级版订单编号
      coupon: '', // 优惠券编码
      couponPrice: '', // 优惠金额
      price: '' // 实际支付费用
    }
  },
  methods: {
    payTypeChange (val) {
      this.activePayType = val
      this.pay(this.activeTab, this.activePayType)
    },
    // 请求二维码
    pay (activeTab, activePayType) {
      // console.log(activeTab, activePayType)
      // 获取微信二维码
      if (activeTab === 'standard' && activePayType === 'wechatPay') {
        getWxPayQrcode({
          pluginId: this.pluginId,
          priceLevel: 1,
          outTradeno: this.standardOutTradeno,
          coupon: this.coupon
        }).then(res => {
          const { code, data, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
            return false
          }
          const { codeUrl, outTradeno, couponPrice, price } = data
          this.qrcodeUrl = codeUrl
          this.standardOutTradeno = outTradeno
          this.couponPrice = couponPrice
          this.price = price
        })
      } else if (activeTab === 'advanced' && activePayType === 'wechatPay') {
        getWxPayQrcode({
          pluginId: this.pluginId,
          isUpdate: this.isUpdate,
          priceLevel: 2,
          outTradeno: this.advancedOutTradeno,
          coupon: this.coupon
        }).then(res => {
          const { code, data, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
            return false
          }
          const { codeUrl, outTradeno, couponPrice, price } = data
          this.qrcodeUrl = codeUrl
          this.advancedOutTradeno = outTradeno
          this.couponPrice = couponPrice
          this.price = price
        })
        // 获取支付宝二维码
      } else if (activeTab === 'standard' && activePayType === 'aliPay') {
        getAliPayQrcode({
          pluginId: this.pluginId,
          priceLevel: 1,
          outTradeno: this.standardOutTradeno,
          coupon: this.coupon
        }).then(res => {
          const { code, data, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
            return false
          }
          const { codeUrl, outTradeno, couponPrice, price } = data
          this.qrcodeUrl = codeUrl
          this.standardOutTradeno = outTradeno
          this.couponPrice = couponPrice
          this.price = price
        })
      } else if (activeTab === 'advanced' && activePayType === 'aliPay') {
        getAliPayQrcode({
          pluginId: this.pluginId,
          isUpdate: this.isUpdate,
          priceLevel: 2,
          outTradeno: this.advancedOutTradeno,
          coupon: this.coupon
        }).then(res => {
          const { code, data, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
            return false
          }
          const { codeUrl, outTradeno, couponPrice, price } = data
          this.qrcodeUrl = codeUrl
          this.advancedOutTradeno = outTradeno
          this.couponPrice = couponPrice
          this.price = price
        })
      }
    },
    setCoupon () {
      if (!this.coupon) {
        this.$message.error('优惠券不能为空!')
      }
      this.pay(this.activeTab, this.activePayType)
    },
    // 用户确认已经支付
    confirmPay () {
      const promises = []
      if (this.standardOutTradeno !== '') {
        promises.push(changeOrderStatus({
          outTradeNo: this.standardOutTradeno
        }), changeOrderStatusAli({
          outTradeNo: this.standardOutTradeno
        }))
      }
      if (this.advancedOutTradeno !== '') {
        promises.push(changeOrderStatus({
          outTradeNo: this.advancedOutTradeno
        }), changeOrderStatusAli({
          outTradeNo: this.advancedOutTradeno
        }))
      }
      if (promises.length > 0) {
        Promise.all(promises).then(res => {
          // console.log(res)
          for (let i = 0; i < res.length; i++) {
            const { code, data } = res[i]
            if (code !== 0) {
              continue
            }
            const { type, status } = data
            if ((type === 'wechatPay' && status === 'SUCCESS') || (type === 'aliPay' && status === 'TRADE_SUCCESS')) {
              this.$emit('confirmPay', data)
              this.die()
              return true
            }
          }
          this.$message.error('您尚未支付!')
        })
      }
    },
    // 轮询检查是否已经支付
    checkPay () {
      timeOut && clearInterval(timeOut)
      timeOut = setInterval(() => {
        const promises = []
        if (this.standardOutTradeno !== '') {
          promises.push(queryOrderStatus({
            outTradeNo: this.standardOutTradeno
          }), queryOrderStatusAli({
            outTradeNo: this.standardOutTradeno
          }))
        }
        if (this.advancedOutTradeno !== '') {
          promises.push(queryOrderStatus({
            outTradeNo: this.advancedOutTradeno
          }), queryOrderStatusAli({
            outTradeNo: this.advancedOutTradeno
          }))
        }

        if (promises.length > 0) {
          Promise.all(promises).then(res => {
            // console.log(res)
            for (let i = 0; i < res.length; i++) {
              const { code, data } = res[i]
              if (code !== 0) {
                continue
              }
              const { type, status } = data
              if ((type === 'wechatPay' && status === 'SUCCESS') || (type === 'aliPay' && status === 'TRADE_SUCCESS')) {
                this.$emit('paySuccess')
                this.die()
                return true
              }
            }
          })
        }
      }, 2000)
    },
    // 退出
    exit () {
      this.$emit('exit')
      this.die()
    },
    die () {
      this.$destroy()
    }
  },
  created () {
    this.activeTab = this.activeTabIndex
    this.checkPay()
  },
  destroyed () {
    this.activeTab = ''
    timeOut && clearInterval(timeOut)
    timeOut = null
  }
}
</script>

<style lang="less" scoped>
.hint {
  height: 40px;
  line-height: 40px;
  color: #b94a48;
}
.coupon-label{
  display: inline-block;
  width: 100%;
  padding: 10px 8px 0 0;
  text-align: right;
}
</style>
