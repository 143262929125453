import request from '@/utils/request'

// 获取支付二维码 (微信)
export function getWxPayQrcode (params) {
  return request({
    url: '/codeStoreFrontAdmin/wxPay',
    method: 'get',
    params
  })
}

// 获取支付二维码 (支付宝)
export function getAliPayQrcode (params) {
  return request({
    url: '/codeStoreFrontAdmin/aliPayQrcode',
    method: 'get',
    params
  })
}

// 查询订单支付状态 (微信)
export function queryOrderStatus (params) {
  return request({
    url: '/codeStoreFrontAdmin/queryOrderStatus',
    method: 'get',
    params
  })
}

// 查询订单支付状态 (阿里)
export function queryOrderStatusAli (params) {
  return request({
    url: '/codeStoreFrontAdmin/queryOrderStatusAli',
    method: 'get',
    params
  })
}

// 更新订单的支付状态 (微信)
export function changeOrderStatus (params) {
  return request({
    url: '/codeStoreFrontAdmin/changeOrderStatus',
    method: 'get',
    params
  })
}

// 更新订单的支付状态 (阿里)
export function changeOrderStatusAli (params) {
  return request({
    url: '/codeStoreFrontAdmin/changeOrderStatusAli',
    method: 'get',
    params
  })
}
